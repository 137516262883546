



.faqs__section {
    padding-left:  20px;
    padding-right: 20px;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 500px;
}

.faqs__text {
    color: white;
    text-align: start;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
}

.faqs__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.link_text {
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    color: rgb(73,73,73);
}

.faqs__detail {
    max-width: 800px;
    margin: 20px 0;
    font-weight: 300;
    color: rgb(73,73,73);
    font-family: 'Helvetica', sans-serif;
    text-align: justify;
}

.faqs__bold {
    font-weight: bold;
    text-transform: uppercase;
}

.faqs-img__girl {
    height: 400px;
    position: absolute;
    left: 20px;
    bottom: 0;
    z-index: 10;
}

.faqs__row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.faqs__row:hover {
    cursor: pointer;
}



@media only screen and (max-width: 530px) {
    .faqs__section {
        padding-left: 0px;
        min-height: 300px;
        justify-content: flex-start;
    }

    .responsive_row {
        flex-direction: column;
    }

    .faqs__text {
        margin-right: 0px;
        padding-right: 0px;
    }    

    .faqs__row p {
        font-size: 14px;
    }

    .faqs__column {
        width: 100%;
    }

    .mt15 {
        margin-top: 15px;
    }

    .responsive_row .faqs__column .faqs__row {
        justify-content: flex-start;
    }

    .responsive_row .faqs__column .faqs__row p {
        width: 70%;
    }

    .faqs-img__girl {
        display: none;
    }
}