:root {
  --margin: 0.25rem;
}
.row {
  display: flex;
}

.row > div {
  margin-right: var(--margin);
}

.row > div:last-child {
  margin-right: 0rem;
}

.column {
  display: flex;
  flex-direction: column;
}

.column > div {
  margin-bottom: var(--margin);
}
.column > div:last-child {
  margin-bottom: 0rem;
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding: 0 1.4rem;
}

.grid {
  gap: 1rem;
  display: grid;
  place-items: initial;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
}

.grid > div {
  width: 100%;
  grid-column: span 2;
}

.col-default,
.col-10 {
  width: 100%;
}
.col-9 {
  width: 90%;
}
.col-8 {
  width: 80%;
}
.col-7 {
  width: 70%;
}
.col-6 {
  width: 60%;
}
.col-5 {
  width: 50%;
}
.col-4 {
  width: 40%;
}
.col-3 {
  width: 30%;
}
.col-2 {
  width: 20%;
}
.col-1 {
  width: 10%;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  .row > div {
    margin-bottom: var(--margin);
  }
  .row > div:last-child {
    margin-bottom: 0rem;
  }
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .row {
    flex-wrap: wrap;
  }
  .col-3,
  .col-2,
  .col-1 {
    width: 100%;
  }
  .col-6,
  .col-5,
  .col-4 {
    max-width: 100%;
  }
}
