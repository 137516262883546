.hug__section{
    height: calc(100vh - 100px);
    width: 100%;
    max-height:calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space;
    background-image: url('https://d3r4ipxr51o1re.cloudfront.net/hug.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 10px;
}

.hug__section p {
    text-size-adjust: auto;

    color: white;
    font-weight: bold;
    font-size: 100px;
    text-align: center;
}

@media only screen and (max-width: 1100px) {

    .hug__section {
        height: 70vh;
    }
    
    .hug__section p {
        font-size: 70px;
        
    }


}
@media only screen and (max-width: 700px) {

    .hug__section {
        height: 70vh;
    }
    
    .hug__section p {
        font-size: 70px;
    }


}


@media only screen and (max-width: 500px) {

    .hug__section {
        height: 50vh;
    }
    
    .hug__section p {
        font-size: 40px;
    }


}
