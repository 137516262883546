

.contact__section {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 500px;
    
    padding: 20px 0;
    position: relative;
    flex-direction: row;
}
.secondContainer{
    flex-direction: column;
    width: 50%;
}
.thirdContainer{
    flex-direction: column;
    width: 80%;

}

.contact__title-h2 {
    font-size: 45px;
    padding: 5px 30px;
    margin-bottom: 20px;
    color: rgb(44, 173, 230);
}
.successForm{
        border: 2px solid rgb(206, 219, 30);
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: justify;
    min-height: 345px;
    padding: 20px;
    position: relative;
}
.success-title{
    font-size: larger;
    font-weight: bolder;
    text-align: center;
    font-family: Helvetica, sans-serif;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.success-content{
    font-family: Helvetica, sans-serif;
    font-size: large;
    font-weight: lighter;
    
}
.sendbutton {
    padding: 10px 20px;
    border: none;
    margin-left: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgb(206, 219, 30);
    color: white;
    font-size: 17px;
    font-weight: bold;
}


.contact__girl{
    height: 30vw;
    position: absolute;
    left: 20px;
    bottom: 0;
    z-index: 10;
}

.contact__column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
}
.error-message{
    width: 140%;
    margin-left: 30px;
    color: #ed1c24;
    font-size: smaller;
}
.contact_row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.contact__title {
    font-size: 20px;
    font-weight: bold;
    color: rgb(44, 173, 230);
}

.contact__input {
    align-self: center;
    border: 2px solid rgb(206, 219, 30);
    border-radius: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    margin-left: 15px;
    width: 70%;
}

.contact__input:focus {
    outline: none !important;
    border:2px solid rgb(44, 173, 230);
}

::-webkit-input-placeholder {
    font-weight: bold;
}

:-moz-placeholder {
    font-weight: bold;
}



@media only screen and (max-width: 1250px) {
    .contact__column {
        width: 40%;
    }

    .contact__girl {
        height: 300px;
    }
}

@media only screen and (max-width: 900px) {
    .contact__column {
        width: 50%;
        margin-left: 0px;
        
    }

    
    .contact__girl {
        display: none;
    }
    .thirdContainer{
        width: 400%;
        position: relative;
        left: -2%;
    
    }
    
    .contact__input {
        width: 80% !important;
    }
    .secondContainer{
        width: 100%;
    }
    
    .contact_form_container {
        margin-left: 30px !important;
        display: flex;
        width: 80%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .contact_form_container textarea {
        margin-left: 35px;
    }

    .sendbutton {
        margin-left: 35px;
    }

    .contact__title {
        font-size: 16px;
    }

    .alert-div {
        margin-left: 35px !important;
    }


    .spinner{
        border:20px solid #f2f2f2;
        border-bottom: 20px solid #33dba9;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        animation: loader 2s linear infinite;
      } 

      

     
}


.loadingdiv {
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    /* coloca el contenedor en la parte superior de la página */
    left: 0;
    /* coloca el contenedor en la parte izquierda de la página */
    width: 100%;
    /* hace que el contenedor ocupe todo el ancho de la página */
    height: 100%;
    /* hace que el contenedor ocupe todo el alto de la página */
    z-index: 1;
  }

.row-button{
    flex-direction: row;

  }
  .column{
    flex-direction: column;
  }