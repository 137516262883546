.button-container-download {
    display: flex;
    align-items: center;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding:10px;
    margin: 5px;
    cursor: pointer;
    
    
  }
  
  .button-container-download img {
    height:45px;
    border-radius:10px;
    margin-right: 10px;
  }

  .button-container-download .text-container{
    text-align: left;
    font-weight: bolder;
    font-size: medium;
    color:white
   
  }
  
  @media (max-width: 600px) {
    .button-container-download {
      flex-direction: column;
      align-items: center;
      background-color: transparent;
      margin: 1px;
      padding: 2px;

    }
  
    .button-container-download .text-container {
        display: none;
      }
    .button-container-download img {
      margin-right: 0;
    }
  }
  
  
  @media (max-width: 400px) {
    .button-container-download {
      flex-direction: column;
      align-items: center;
      background-color: transparent;


    }
    .button-container-download  img {
      height:40px;
      width: 40px;
      border-radius:10px;
      margin-right: 3px;
    }
  }
  