@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;900&family=Staatliches&display=swap");

.mainContainer {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: rgb(247, 247, 242);
    background: linear-gradient(311deg, rgba(247, 247, 242, 1) 35%, rgba(255, 255, 255, 1) 56%, rgba(234, 234, 220, 1) 85%, rgba(235, 235, 235, 1) 90%);
    min-height: 100vh;
}

.mainCar {
    padding: 0px !important;
    display: flex;
    flex-direction: row !important;
}

.avatarReferral {
    width: 120px !important;
    height: 50px !important;
}

.cardContainer {
    width: 90%;
}

.cardLeftSignUp {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardRigthSignUp {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(311deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 2%, rgba(192, 235, 249, 1) 20%, rgba(180, 222, 232, 1) 46%, rgba(207, 232, 208, 1) 66%, rgba(247, 247, 247, 1) 89%, rgba(255, 255, 255, 1) 100%);
    padding-bottom: 60px;
}

.appIcon {
    width: 30%;
    height: auto;
}

.titleCard {
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
    font-size: medium;
}

.formContainer {
    width: 80%;
    margin-bottom: 5rem;
}

.footerContainer {
    position: absolute;
    bottom: 0px;
    width: 50% !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 2rem 1rem 2rem !important;
}


.containerReferral {
    width: 100%;
    padding: 0px 10px 0px 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.divider {
    display: none;
}

.userReferralSignUp {
    display: none;
}

.modalFooter {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
}

@media (max-width: 1280px) {
    .cardRigthSignUp {
        display: none;
    }

    .userReferralSignUp {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 50px;
        margin: 4rem 0 7rem 0;
        width: 80%;
    }

    .avatarReferral {
        width: 100px !important;
        height: 100px !important;
    }

    .footerContainer {
        position: absolute;
        bottom: 0px;
        width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

}

@media (max-width: 640px) {

    .userReferralSignUp {
        flex-direction: column;
    }

    .formContainer {
        width: 80%;
        margin-bottom: 0rem;
    }

    .appIcon {
        width: 60%;
        height: auto;
    }

    .cardContainer {
        width: 100%;
    }

    .cardInfoTitle {
        font-size: 1.5rem !important;
        text-align: center;
        margin-top: -40px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .cardInfoSubTitle {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .avatarReferral {
        /* width: 30% !important; */
        width: 70px !important;
        height: 70px !important;
    }

    .termsCondSignUp {
        text-align: center;
        margin-right: -20px;
    }

    .mainContainer {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .divider {
        display: flex;
    }
}