.why__section {
    padding: 80px 20px;
    background-color: rgb(206, 219, 30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.why__text {
    color: rgb(74, 75, 73);
    text-align: start;
    font-weight: bold;
    font-size: 22px;
}

.why__text:hover, .manito:hover {
    cursor: pointer;
}

.why__column {
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.why-img__girl {
    height: 340px;
    position: absolute;
    left: 4vw;
    bottom: 0px;
    z-index: 10;
}

.why-img__hand {
    height: 340px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    /* transform: scaleX(-1); */
}

.why__row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.crear_text {
    width: 35%;
    margin-left: 30px;
    color: rgb(74, 75, 73);
    font-weight: bold;
    font-family: Helvetica, sans-serif;
    text-align: justify;
}

.why-img__phone {
    height: 217px;
    width: 117px;
    position: absolute;
    right: 168px;
    transform: rotate(-5deg);
    bottom: 90px;
    z-index: 11;
}

  
@media only screen and (max-width: 1200px) {
    .why-img__hand, .why-img__phone {
        display: none;
    }
}

@media only screen and (max-width: 1100px) {
    .why-img__girl {
        height: 240px;
        left: 10px;
    }
}

@media only screen and (max-width: 880px) {
    .why-img__girl {
        display: none;
    }

    .crear_text {
        width: 100%;
        padding-right: 20px;
    }
}