

.footer__row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 0;
}

.footer__row p, .footer__p {
    color: rgb(206, 219, 30);
    flex: 1;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
}

@media only screen and (max-width: 530px) {
    .footer__row {
        flex-direction: column;
    }

    .footer__p {
        margin: 10px 0px;
    }
}