

.tutorials__section{
    padding: 60px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.yt_player_iframe{
    width: 35rem;
    height: 20rem;
}
.tutorials__title {
    font-size: 40px;
    font-weight: bold;
    color: rgb(206, 219, 30);
}

.tutorials__subtitle {
    color: rgb(44, 173, 230);
    font-weight: bold;
}

.grid-container {
    margin-top: 30px;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    /* grid-gap: 20px; */
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
}
  
.grid-item {
    text-align: center;
    width: 300px;
}

.tutorials__element {
    display: flex;
    height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.tutorials__preview {
    height: 80%;
    margin-bottom: 5px;
    width: 100%;
    position: relative;
}

.tutorials__desc {
    color: rgb(206, 219, 30);
    text-align: start;
    padding-left: 10px;
}

.tutorials__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.elemento-delante {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  
  
  
  
.modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: none;
    padding-top: 90px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    position: relative;
    min-width: 320px;
    max-width: 560px;
    min-height: 200px;
    max-height: 600px;
  }
  
  .modal-close {
    position: absolute;
    top: 0.6rem;
    right: 1rem;
    background-color: transparent;
    font-size: 30px;
    font-weight: bold;
    color: white;
    border: none;
  }
  
  .modal.is-open {
    display: flex;
  }

  @media only screen and (max-width: 630px) {
    .tutorials__preview {
        height: 80%;
        margin-bottom: 5px;
        width: 100%;
        position: relative;
    }
    .yt_player_iframe{
        width: 28rem;
        height: 16rem;

    }
}

@media only screen and (max-width: 420px) {
    .yt_player_iframe{
        width: 20rem;
        height: 11.5rem;

    }
}