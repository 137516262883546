

.why-page__section {
    margin-top: 80px;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 430px;
}

.whypage__row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.why-page__h3 {
    margin-left: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
    color: rgb(44, 173, 230);
}

.why-page__p {
    color: rgb(74, 75, 73);
    font-family: 'Helvetica', sans-serif;
    font-weight: 300;
}

.why-page__column{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.why-img__boy {
    height: 400px;
    position: absolute;
    left: 20px;
    bottom: -1px;
    z-index: 10;
}

.why-page_showfaq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    min-height: 350px;
}

.why-page_dontfaq {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    width: 100%;
    margin-left: 50px;
}

.why-page_dontfaqcol {
    margin-left: 0;
    width: 100%;
}

.ml50 {
    margin-left: 50px;
}

.ml40 {
    margin-left: 40px;
}

.why-container {
    margin-left: 50px;
    width: 65%;
}

.faqs__text:hover{
    color: rgb(44, 173, 230);
}
@media only screen and (max-width: 1100px) {
    .why-img__boy {
        height: 300px;
        left: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .why-img__boy {
        display: none;
    }
    .why-container {
        margin: 0;
        width: 100%;
    }
    .whypage__row {
        padding: 0 20px;
        text-align: center;
    }
    .faqspage__title {
        font-size: 30px;
    }
    .why-page_showfaq {
        width: 100%;
        min-height: 200px;
        padding-right: 10px;
    }
    .why-page__section {
        min-height: 200px;
    }
    .why-page_dontfaq {
        margin-left: 0;
    }

    .ml50{
        margin-left: 30px;
    }

    .faqs__detail {
        margin: 0px;
    }

    .why-page__p {
        padding: 0 20px;
        margin-bottom: 10px;
    }

    .ml40 p {
        padding-left: 5px;
    }

    .faqs__text {
        padding-right: 10px;
    }
}